<template>
  <div class="c-alpha-resend-email">
    <div class="c-alpha-resend-email__content">
      <div class="c-alpha-resend-email__content-wrapper">
        <h2 class="c-alpha-resend-email__content-title">New <span class="c-alpha-resend-email__content-title_blue">Verification Email</span></h2>
        <p class="c-alpha-resend-email__content-desc">Please enter your email address for a new validation link. Validation link is invalid or it may have expired. You may need to check your spam folder or unblock {{ noreply }}</p>
        <div class="c-alpha-resend-email__content-form">
          <div class="c-alpha-resend-email__content-form-container">
            <div class="c-alpha-resend-email__content-form-container-fg">
              <div class="c-alpha-resend-email__content-form-container-fg-box">
                <input class="c-alpha-resend-email__content-form-container-fg-box-input" type = "email" name="email" placeholder = "email address"  v-model="input.email" @input="debouncedPasswordCheck" required>
              </div>
              <div class="c-alpha-resend-email__content-form-container-fg-error">{{errorText}}</div>
              <span class="error-color"></span>
            </div>
          </div>
          <button class="c-alpha-resend-email__content-form-button" :class="{'c-alpha-resend-email__content-form-button_disable': validEmail == null || validEmail == false}" @click="resetPassword">Submit</button>
        </div>
      </div>
    </div>
    <div id="alertModal" class="c-alpha-resend-email__modal" v-if="showAlert">
      <div class="c-alpha-resend-email__modal-alert">
        <div class = "c-alpha-resend-email__modal-alert-header">
          <img src = "@/assets/images/old-alpha/logo.png" class="c-alpha-resend-email__modal-alert-header-img" alt="Sparrow Exchange">
        </div>
        <div class="c-alpha-resend-email__modal-alert-close" id = "alertModalClose">
          <img class="c-alpha-resend-email__modal-alert-close-img" src = "@/assets/images/old-alpha/X-icon-01.png" alt="Close Button">
        </div>
        <div class = "c-alpha-resend-email__modal-body">
          <div class="c-alpha-resend-email__modal-body-content">
            <!-- from js -->
          </div>
          <div class="modal-body-footer">
            <button id="submit-alert" class="c-alpha-resend-email__modal-body-content-button" translate="resend_verification_buttonok">OK</button>
          </div>
        </div>
      </div>
    </div>
    <div id="suspendModal" class="c-alpha-resend-email__modal c-alpha-resend-email__modal_darker" v-if="showSuspend">
      <div class="c-alpha-resend-email__modal-suspend">
        <img src="@/assets/images/old-alpha/alert.png" class="c-alpha-resend-email__modal-suspend-img" alt="Alert">
        <h1 class="c-alpha-resend-email__modal-suspend-title">Account <span class="c-alpha-resend-email__modal-suspend-title_bold">Suspended</span></h1>
        <div class="c-alpha-resend-email__modal-suspend-desc">Your account has been suspended. <br />Please contact support for help.</div>
        <div class="modal-btn">Ok</div>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce';

export default {
  data() {
    return {
      toggleEye: false,
      showAlert: false,
      showSuspend: false,
      input:{
        email: '',
      },
      validEmail: null,
      errorText: ''
    }
  },
  mounted() {
  },
  methods: {
    checkPasswordValidity: function(){
      /* eslint-disable */
      let mailFormat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      /* eslint-enable */
      this.validEmail = this.input.email!='' && mailFormat.test(this.input.email);
    },
    resetPassword() {
      let _this = this
      if(this.validEmail == true) {
        this.$http.post(this.createApiUrlChestNut("users/resend-verification"), {
          "email": this.input.email
        }).then(() =>{
          _this.openLink('/emailsent-verification')
        },err => {
          this.errorText = err.body.message
        });
      }
    }
  },
  computed: {
    debouncedPasswordCheck: function(){
      return _debounce(this.checkPasswordValidity, 100);
    },
    noreply: function() {
      return this.noReplyURL()
    }
  },
  watch: {

  }
}
</script>
